<template>
  <div class="i18n relative">

    <span class="cursor-pointer flex items-center i18n-locale" @click="show = !show">
      <img class="h-4 w-5" :src="i18n_locale_img" :alt="$i18n.locale"/>
      <span v-if="!hideName" class="hidden sm:block useral-font-weight-medium ml-2">{{ getCurrentLocaleData.lang }}</span>
    </span>

    <custom-drop-down :list="list"
                      :show="show"
                      @closed="show = false"
                      @selectFa="updateLocale('fa')"
                      @selectAr="updateLocale('ar')"
                      @selectEn="updateLocale('en')"/>
  </div>
</template>

<script>

import CustomDropDown from '@/components/customDropDown/customDropDown'

export default {
  components: {CustomDropDown},
  computed: {
    i18n_locale_img () {
      // Use below code to dynamically fetch image instead of if-else
      // NOTE: We used if-else because laravel throws error in 'yarn prod'.
      // If you are not using laravel + Vue, you can use below code to dynamically get image
      // return require(`@/assets/images/flags/${this.$i18n.locale}.png`)

      const locale = this.$i18n.locale
      if (locale === 'en') return require('@/assets/images/flags/en.png')
      else if (locale === 'fa') return require('@/assets/images/flags/fa.png')
      else if (locale === 'ar') return require('@/assets/images/flags/ar.png')
      else return require('@/assets/images/flags/fa.png')
    },
    getCurrentLocaleData () {
      const locale = this.$i18n.locale
      if (locale === 'en') return {flag: 'us', lang: 'English'}
      else if (locale === 'ar') return {flag: 'ar', lang: 'العربیه'}
      else if (locale === 'fa') return {flag: 'fa', lang: 'فارسی'}
    }
  },
  props: {
    hideName: false
  },
  data () {
    return {
      show: false,
      list: [
        {
          type: 'img',
          imgClass: 'h-4 w-5 mr-1',
          src: require('@/assets/images/flags/fa.png'),
          label: 'فارسی',
          event: 'selectFa'
        },
        {
          type: 'img',
          imgClass: 'h-4 w-5 mr-1',
          src: require('@/assets/images/flags/ar.png'),
          label: 'العربیه',
          event: 'selectAr'
        },
        {
          type: 'img',
          imgClass: 'h-4 w-5 mr-1',
          src: require('@/assets/images/flags/en.png'),
          label: 'English',
          event: 'selectEn'
        }
      ]
    }
  },
  created () {
    this.updateLocale('fa')
  },
  methods: {
    updateLocale (locale) {
      this.$i18n.locale = locale
      localStorage.setItem('userLocale', locale)
      if (locale === 'en') {
        this.$vs.rtl = false
      } else if (locale === 'fa') {
        this.$vs.rtl = true
      } else if (locale === 'ar') {
        this.$vs.rtl = true
      }
    }
  }
}
</script>
